import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';
import es from 'date-fns/locale/es'; // Para manejar el idioma español
import '../styles/PedidosHistoricos.css';

// Importación de imágenes para los semáforos
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';

function PedidosHistoricos() {
    const [pedidos, setPedidos] = useState([]);
    const [filtros, setFiltros] = useState({
        fechaInicio: null,
        fechaFin: null,
        estado: '',
        movil: '',
        origen: '',
        ciudad: '',
        busqueda: ''
    });
    const [origenesUnicos, setOrigenesUnicos] = useState([]);
    const [ultimaVersionJson, setUltimaVersionJson] = useState(null); // Para guardar el estado del archivo

    // Cargar los pedidos históricos desde el JSON
    const cargarPedidosHistoricos = async () => {
        try {
            const response = await fetch('/pedidos/pedido_callcenter_historico.json', { cache: 'no-store' });
            const data = await response.json();
            
            // Solo actualizar si el archivo ha cambiado (comparamos con la última versión)
            const dataString = JSON.stringify(data);
            if (dataString !== ultimaVersionJson) {
                setUltimaVersionJson(dataString);  // Actualizamos la versión actual del archivo
                setPedidos(data);
                // Obtener valores únicos de Origen
                const origenes = [...new Set(data.map(pedido => pedido.campo_12))];
                setOrigenesUnicos(origenes);
            }
        } catch (error) {
            console.error('Error al cargar los pedidos históricos:', error);
        }
    };

    // Polling: recargar los datos cada 30 segundos para detectar cambios en el archivo
    useEffect(() => {
        cargarPedidosHistoricos(); // Carga inicial
        const intervalId = setInterval(() => {
            cargarPedidosHistoricos(); // Polling cada 30 segundos
        }, 30000); // Intervalo de 30 segundos

        return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
    }, [ultimaVersionJson]);

    // Manejar cambios en los filtros
    const handleFiltroChange = (e) => {
        setFiltros({
            ...filtros,
            [e.target.name]: e.target.value
        });
    };

    // Función para formatear la fecha del campo_5 a Date para comparaciones
    const parsearFecha = (fechaStr) => {
        return parse(fechaStr.split(',')[0], 'dd-MM-yyyy', new Date()); // Tomamos solo la fecha dd-MM-yyyy
    };

    // Función de filtrado
    const filtrarPedidos = (pedido) => {
        const { fechaInicio, fechaFin, estado, movil, origen, ciudad, busqueda } = filtros;

        // Filtrado por búsqueda universal
        const busquedaNormalizada = busqueda.toLowerCase();
        const camposConcatenados = Object.values(pedido).join(' ').toLowerCase();
        if (busqueda && !camposConcatenados.includes(busquedaNormalizada)) {
            return false;
        }

        // Filtrado por fecha usando el rango
        const fechaPedido = parsearFecha(pedido.campo_5);
        if (fechaInicio && fechaPedido < fechaInicio) return false;
        if (fechaFin && fechaPedido > fechaFin) return false;

        // Filtrado por cada columna
        if (estado && pedido.campo_6 !== estado) return false;
        if (movil && pedido.campo_11 !== movil) return false;
        if (origen && pedido.campo_12 !== origen) return false;
        if (ciudad && pedido.campo_yy !== ciudad) return false;

        return true;
    };

    // Función para calcular la diferencia de tiempo entre la fecha del pedido y el momento actual
    const calcularDiferenciaTiempo = (fechaPedido) => {
        const fecha = parse(fechaPedido.split(',')[0], 'dd-MM-yyyy', new Date());
        const ahora = new Date();
        const diferenciaMinutos = Math.floor((ahora - fecha) / (1000 * 60)); // Diferencia en minutos
        return diferenciaMinutos;
    };

    // Obtener la imagen del semáforo según el tiempo transcurrido
    const obtenerSemaforoYColor = (diferenciaMinutos) => {
        if (diferenciaMinutos < 6) return img1;  // Verde
        if (diferenciaMinutos < 12) return img2; // Amarillo
        if (diferenciaMinutos < 18) return img3; // Naranja
        if (diferenciaMinutos < 24) return img4; // Rojo
        return img5;  // Morado
    };

    return (
        <div className="pedidos-historicos-container">
            <h1>Pedidos Históricos</h1>

            <div className="filtros-container">
                {/* Filtro de búsqueda universal */}
                <input
                    type="text"
                    name="busqueda"
                    value={filtros.busqueda}
                    placeholder="Buscar en todas las columnas..."
                    onChange={handleFiltroChange}
                />

                {/* Filtro de rango de fechas con DatePicker */}
                <DatePicker
                    selected={filtros.fechaInicio}
                    onChange={(date) => setFiltros({ ...filtros, fechaInicio: date })}
                    placeholderText="Desde fecha"
                    dateFormat="dd-MM-yyyy"
                    locale={es}
                    isClearable
                />
                <DatePicker
                    selected={filtros.fechaFin}
                    onChange={(date) => setFiltros({ ...filtros, fechaFin: date })}
                    placeholderText="Hasta fecha"
                    dateFormat="dd-MM-yyyy"
                    locale={es}
                    isClearable
                />

                {/* Filtro de estado */}
                <select name="estado" value={filtros.estado} onChange={handleFiltroChange}>
                    <option value="">Filtrar por estado</option>
                    <option value="Despacho">Despacho</option>
                    <option value="Entregado">Entregado</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Anulado">Anulado</option>
                </select>

                {/* Filtro de móvil */}
                <input
                    type="text"
                    name="movil"
                    value={filtros.movil}
                    placeholder="Filtrar por móvil..."
                    onChange={handleFiltroChange}
                />

                {/* Filtro de origen */}
                <select name="origen" value={filtros.origen} onChange={handleFiltroChange}>
                    <option value="">Filtrar por origen</option>
                    {origenesUnicos.map((origen, index) => (
                        <option key={index} value={origen}>
                            {origen}
                        </option>
                    ))}
                </select>

                {/* Filtro de ciudad */}
                <select name="ciudad" value={filtros.ciudad} onChange={handleFiltroChange}>
                    <option value="">Filtrar por ciudad</option>
                    <option value="Arica">Arica</option>
                    <option value="Antofagasta">Antofagasta</option>
                </select>
            </div>

            {/* Tabla de pedidos históricos */}
            <table className="pedidos-historicos-tabla">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>N° del Pedido</th>
                        <th>Semáforo</th> {/* Nueva columna de semáforo */}
                        <th>Teléfono</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Dirección</th>
                        <th>Producto1</th>
                        <th>Producto2</th>
                        <th>Movil</th>
                        <th>Origen</th>
                        <th>Ciudad</th>
                        <th>Tiempo</th>
                    </tr>
                </thead>
                <tbody>
                    {pedidos.filter(filtrarPedidos).map((pedido, index) => {
                        const diferenciaMinutos = calcularDiferenciaTiempo(pedido.campo_5);
                        const semaforoImg = obtenerSemaforoYColor(diferenciaMinutos);

                        return (
                            <tr key={index}>
                                <td>{pedido.campo_1 || index + 1}</td>
                                <td>{pedido.campo_2}</td>
                                <td><img src={semaforoImg} alt="Semáforo" width="40" /></td> {/* Imagen de semáforo */}
                                <td>{pedido.campo_4}</td>
                                <td>{pedido.campo_5}</td>
                                <td>{pedido.campo_6}</td>
                                <td>{pedido.campo_7}</td>
                                <td>{pedido.campo_8}</td>
                                <td>{pedido.campo_9}</td>
                                <td>{pedido.campo_11}</td>
                                <td>{pedido.campo_12}</td>
                                <td>{pedido.campo_yy}</td>
                                <td>{pedido.campo_xx}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default PedidosHistoricos;
