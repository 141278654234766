import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// Importaciones de imágenes y estilos
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
import editarImg from '../images/editar.png';
import eliminarImg from '../images/eliminar.png';
import cambiarEstadoImg from '../images/cambiar.png';
import mapaImg from '../images/mapa.png';
import telegramImg from '../images/telegram.webp';
import '../styles/Pedidos.css';

dayjs.extend(customParseFormat); // Extiende dayjs para manejar formatos personalizados

// Opciones de productos que estarán disponibles en la interfaz
const opcionesProductos = [
    'Gas 15kg Normal',
    'Gas 45kg Normal',
    'Gas 5kg Normal',
    'Gas 11kg Normal',
    'Gas 15kg VMA',
];

// Opciones de estado que pueden tener los pedidos
const opcionesEstado = ['Despacho', 'Entregado', 'Pendiente', 'Anulado'];

// Opciones de ciudades que pertenecen al Call Center
const opcionesCiudad = ['Arica', 'Antofagasta']; // Lista desplegable para Call Center

// Componente principal de Pedidos
function Pedidos() {
    // Estado para manejar la lista de pedidos cargados
    const [pedidos, setPedidos] = useState([]);
    
    // Estado para manejar la creación de nuevas filas de pedidos
    const [nuevaFila, setNuevaFila] = useState({
        campo_2: generarNumeroPedido(),
        campo_4: '', // Teléfono
        campo_7: '', // Dirección
        campo_8: '', // Producto1
        campo_9: '', // Producto2
        campo_10: '', // Precio
        campo_11: '', // Movil
        campo_12: 'Call Center', // Origen
        campo_6: 'Despacho', // Estado inicial
        campo_yy: '', // Ciudad para Call Center
    });

    const [editando, setEditando] = useState(null); // Control de la fila que se está editando
    const [editandoEstado, setEditandoEstado] = useState(null); // Control de la fila que está cambiando su estado
    const [errores, setErrores] = useState(''); // Manejo de errores para campos incompletos
    const inputDireccionRef = useRef(null); // Referencia al input de dirección para usar con Google Places API

    // Función que genera un número aleatorio de pedido
    function generarNumeroPedido() {
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        return `90${randomNumber}`; // Los números de pedido empiezan con 90 seguido de 6 dígitos
    }

    // Función que carga los datos de los pedidos desde los tres archivos JSON
    const cargarDatos = async () => {
        try {
            const [pedidosArica, pedidosAntofagasta, pedidosCallCenter] = await Promise.all([
                fetch('/pedidos.json', { cache: 'no-store' }).then((res) => res.json()),
                fetch('/pedidos_antofagasta.json', { cache: 'no-store' }).then((res) => res.json()),
                fetch('/pedidos/pedido_callcenter.json', { cache: 'no-store' }).then((res) => res.json()),
            ]);

            // Si el JSON no es un array, transformarlo
            const pedidosAricaArray = Array.isArray(pedidosArica)
                ? pedidosArica
                : Object.keys(pedidosArica).map((key) => pedidosArica[key]);

            if (!Array.isArray(pedidosAntofagasta) || !Array.isArray(pedidosCallCenter)) {
                throw new Error('Uno de los archivos JSON no contiene un array');
            }

            // Asignar ciudad a cada pedido según su origen
            const pedidosAricaConCiudad = pedidosAricaArray.map((pedido) => ({ ...pedido, campo_yy: 'Arica' }));
            const pedidosAntofagastaConCiudad = pedidosAntofagasta.map((pedido) => ({
                ...pedido,
                campo_yy: 'Antofagasta',
            }));

            // Combinar los pedidos y calcular tiempo y semáforo
            const pedidosCombinados = [...pedidosAricaConCiudad, ...pedidosAntofagastaConCiudad, ...pedidosCallCenter].map((pedido) => {
                const { diferenciaMinutos, diferenciaSegundos } = calcularDiferenciaTiempo(pedido.campo_5);
                return {
                    ...pedido,
                    tiempoFormateado: formatearTiempo(diferenciaSegundos),
                    semaforoImagen: obtenerSemaforoYColor(diferenciaMinutos).imagen
                };
            });

            // Eliminar duplicados en la lista combinada de pedidos
            const pedidosUnicos = pedidosCombinados.reduce((acc, current) => {
                const x = acc.find((item) => item.campo_2 === current.campo_2);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            setPedidos(pedidosUnicos);
        } catch (error) {
            console.error('Error al cargar los pedidos:', error);
        }
    };

    // Función que actualiza solo las filas que hayan cambiado
    const actualizarSoloFila = async () => {
        try {
            const [pedidosArica, pedidosAntofagasta, pedidosCallCenter] = await Promise.all([
                fetch('/pedidos.json', { cache: 'no-store' }).then((res) => res.json()),
                fetch('/pedidos_antofagasta.json', { cache: 'no-store' }).then((res) => res.json()),
                fetch('/pedidos/pedido_callcenter.json', { cache: 'no-store' }).then((res) => res.json()),
            ]);

            const pedidosAricaArray = Array.isArray(pedidosArica)
                ? pedidosArica
                : Object.keys(pedidosArica).map((key) => pedidosArica[key]);

            const pedidosAntofagastaConCiudad = pedidosAntofagasta.map((pedido) => ({
                ...pedido,
                campo_yy: 'Antofagasta',
            }));

            const pedidosCallCenterConCiudad = pedidosCallCenter.map((pedido) => ({
                ...pedido,
                campo_yy: 'Call Center',
            }));

            const pedidosCombinados = [...pedidosAricaArray, ...pedidosAntofagastaConCiudad, ...pedidosCallCenterConCiudad];

            // Comparar y actualizar los pedidos que han cambiado
            setPedidos((prevPedidos) =>
                prevPedidos.map((pedidoAntiguo) => {
                    const pedidoNuevo = pedidosCombinados.find((pedido) => pedido.campo_2 === pedidoAntiguo.campo_2);

                    // Si el nuevo pedido es diferente, actualizarlo
                    if (pedidoNuevo && JSON.stringify(pedidoAntiguo) !== JSON.stringify(pedidoNuevo)) {
                        const { diferenciaMinutos, diferenciaSegundos } = calcularDiferenciaTiempo(pedidoNuevo.campo_5);

                        // Verificar si el estado cambió a 'Anulado' o 'Entregado'
                        if (pedidoNuevo.campo_6 === 'Anulado' || pedidoNuevo.campo_6 === 'Entregado') {
                            // Mover el pedido a histórico
                            enviarPedidoAHistorico(pedidoNuevo);
                        }

                        // Actualizar solo la fila modificada
                        return {
                            ...pedidoNuevo,
                            tiempoFormateado: formatearTiempo(diferenciaSegundos),
                            semaforoImagen: obtenerSemaforoYColor(diferenciaMinutos).imagen,
                        };
                    }

                    // Retornar el pedido original si no ha cambiado
                    return pedidoAntiguo;
                })
            );
        } catch (error) {
            console.error('Error al actualizar la fila del pedido:', error);
        }
    };

    // Función para mover un pedido a histórico al cambiar su estado
    const moverPedidoAHistoricoPHP = async (pedido) => {
        try {
            const response = await fetch('/pedidos/mover_pedidosduo_a_historico.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });

            if (!response.ok) {
                throw new Error('Error al mover el pedido a histórico');
            }
        } catch (error) {
            console.error('Error al mover el pedido a histórico:', error);
        }
    };

    // Intervalo de actualización del cronómetro cada segundo
    useEffect(() => {
        const intervalId = setInterval(() => {
            setPedidos((prevPedidos) =>
                prevPedidos.map(pedido => {
                    const { diferenciaMinutos, diferenciaSegundos } = calcularDiferenciaTiempo(pedido.campo_5);
                    return {
                        ...pedido,
                        tiempoFormateado: formatearTiempo(diferenciaSegundos),
                        semaforoImagen: obtenerSemaforoYColor(diferenciaMinutos).imagen
                    };
                })
            );
        }, 1000);

        // Limpiar el intervalo cuando se desmonte el componente
        return () => clearInterval(intervalId);
    }, []);

    // Intervalo de actualización de las filas cada 5 segundos
    useEffect(() => {
        const intervalId = setInterval(() => {
            actualizarSoloFila(); // Actualiza solo las filas que hayan cambiado
        }, 5000);

        return () => clearInterval(intervalId); // Limpiar el intervalo cuando se desmonte el componente
    }, []);

    // Inicializar el autocompletado de Google Places para las direcciones
    useEffect(() => {
        const loadGoogleMapsScript = () => {
            const existingScript = document.getElementById('googleMaps');
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyA5Y8wbArMW8_LBZKbx65VSpjsSgdPvbno&libraries=places&language=es";
                script.id = 'googleMaps';
                script.async = true; // Cargar de manera asíncrona
                document.body.appendChild(script);

                script.onload = () => {
                    initAutocomplete();
                };
            } else {
                initAutocomplete();
            }
        };

        // Configurar el autocompletado para el campo de direcciones
        const initAutocomplete = () => {
            if (window.google) {
                const autocomplete = new window.google.maps.places.Autocomplete(inputDireccionRef.current, {
                    types: ['address'],
                    componentRestrictions: { country: 'cl' },
                });
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place && place.formatted_address) {
                        setNuevaFila((prevFila) => ({ ...prevFila, campo_7: place.formatted_address }));
                    }
                });
            }
        };

        loadGoogleMapsScript(); // Cargar el script de Google Maps
    }, []);


    // Guardar un pedido en el archivo correspondiente (pedido_callcenter.json)
    const guardarEnArchivo = async (pedido) => {
        try {
            const response = await fetch('/pedidos/guardar_pedido.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });
            if (!response.ok) {
                throw new Error('Error al guardar el pedido');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const enviarPedidoAHistorico = async (pedido) => {
        try {
            const response = await fetch('/pedidos/mover_a_historico.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });
    
            if (!response.ok) {
                throw new Error('Error al mover el pedido a histórico');
            }
    
            console.log(`Pedido ${pedido.campo_2} movido a histórico`);
        } catch (error) {
            console.error('Error al mover el pedido a histórico:', error);
        }
    };
    
    

    // Actualizar un pedido existente
    const actualizarPedido = async (pedido) => {
        try {
            const response = await fetch('/pedidos/actualizar_pedido.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });
            if (!response.ok) {
                throw new Error('Error al actualizar el pedido');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Eliminar un pedido
    const eliminarPedido = async (pedido) => {
        try {
            const response = await fetch('/pedidos/eliminar_pedido.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ campo_2: pedido.campo_2, origen: pedido.campo_12, ciudad: pedido.campo_yy }),
            });
            if (!response.ok) {
                throw new Error('Error al eliminar el pedido');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Mover un pedido al archivo histórico cuando su estado cambie a "Anulado" o "Entregado"
    const handleEstadoChange = async (e, index) => {
        const nuevosPedidos = [...pedidos];
        const pedidoModificado = nuevosPedidos[index];
        const nuevoEstado = e.target.value; // El nuevo estado seleccionado
    
        // Actualizar el estado en el pedido modificado
        pedidoModificado.campo_6 = nuevoEstado;
    
        try {
            // Solo mover a histórico si el estado cambió a 'Anulado' o 'Entregado'
            if (nuevoEstado === 'Anulado' || nuevoEstado === 'Entregado') {
                const tiempoTotal = detenerTiempo(pedidoModificado.campo_5); // Detener el tiempo
                pedidoModificado.campo_xx = tiempoTotal; // Guardar el tiempo como nuevo campo
    
                // Mover a histórico solo en este momento
                await enviarPedidoAHistorico(pedidoModificado);
                await eliminarPedido(pedidoModificado); // Eliminar del archivo original
    
                // Recargar los datos para reflejar el cambio
                await cargarDatos();
            } else {
                // Si el estado no es 'Anulado' o 'Entregado', solo actualizar el pedido
                await actualizarPedido(pedidoModificado);
                setPedidos(nuevosPedidos);
            }
        } catch (error) {
            console.error('Error al cambiar el estado del pedido:', error);
        }
    
        // Desactivar el modo edición del estado
        setEditandoEstado(null);
    };   
    
    // Validar que los campos obligatorios estén completos
    const validarCampos = (fila) => {
        if (!fila.campo_4 || !fila.campo_7 || !fila.campo_8 || !fila.campo_11 || !fila.campo_yy) {
            return false; // Si falta algún campo obligatorio
        }
        return true; // Todos los campos están completos
    };

    // Detener el tiempo cuando el estado cambie a "Anulado" o "Entregado"
    const detenerTiempo = (fechaPedido) => {
        const fecha = dayjs(fechaPedido, 'DD-MM-YYYY, HH:mm');
        const ahora = dayjs();
        const diferenciaSegundos = ahora.diff(fecha, 'second');
        return formatearTiempo(diferenciaSegundos);
    };

    // Calcular la diferencia de tiempo entre la fecha del pedido y el momento actual
    const calcularDiferenciaTiempo = (fechaPedido) => {
        const fecha = dayjs(fechaPedido, 'DD-MM-YYYY, HH:mm');
        const ahora = dayjs();
        const diferenciaMinutos = ahora.diff(fecha, 'minute');
        const diferenciaSegundos = ahora.diff(fecha, 'second');
        return { diferenciaMinutos, diferenciaSegundos };
    };

    // Formatear el tiempo en hh:mm:ss
    const formatearTiempo = (segundosTotales) => {
        const horas = Math.floor(segundosTotales / 3600);
        const minutos = Math.floor((segundosTotales % 3600) / 60);
        const segundos = segundosTotales % 60;
        return `${horas.toString().padStart(2, '0')}:${minutos
            .toString()
            .padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
    };

    // Obtener la imagen del semáforo y el color según el tiempo transcurrido
    const obtenerSemaforoYColor = (diferenciaMinutos) => {
        if (diferenciaMinutos < 6) return { imagen: img1, color: 'verde', nombre: 'Semáforo 1' };
        if (diferenciaMinutos < 12) return { imagen: img2, color: 'amarillo', nombre: 'Semáforo 2' };
        if (diferenciaMinutos < 18) return { imagen: img3, color: 'naranja', nombre: 'Semáforo 3' };
        if (diferenciaMinutos < 24) return { imagen: img4, color: 'rojo', nombre: 'Semáforo 4' };
        return { imagen: img5, color: 'morado', nombre: 'Semáforo 5' };
    };

    const handleNuevaFilaChange = (e) => {
        setNuevaFila({ ...nuevaFila, [e.target.name]: e.target.value });
    };

    const handleEditChange = (e, index) => {
        const nuevosPedidos = [...pedidos];
        nuevosPedidos[index] = { ...nuevosPedidos[index], [e.target.name]: e.target.value };
        setPedidos(nuevosPedidos);
    };

    const guardarFila = async () => {
        if (!validarCampos(nuevaFila)) {
            setErrores('Por favor, completa todos los campos obligatorios.');
            return;
        }

        setErrores(''); // Limpiar errores si la validación es correcta
        const nuevaFecha = dayjs().format('DD-MM-YYYY, HH:mm');
        const nuevoPedido = {
            ...nuevaFila,
            campo_1: pedidos.length + 1,
            campo_5: nuevaFecha,
            campo_7: nuevaFila.campo_7.toUpperCase(),
        };

        try {
            await guardarEnArchivo(nuevoPedido); // Guardar en pedido_callcenter.json
            await cargarDatos(); // Recargar los datos para reflejar el nuevo pedido
            setNuevaFila({
                campo_2: generarNumeroPedido(),
                campo_4: '',
                campo_7: '',
                campo_8: '',
                campo_9: '',
                campo_10: '',
                campo_11: '',
                campo_12: 'Call Center',
                campo_6: 'Despacho',
                campo_yy: '', // Reiniciar la ciudad seleccionada
            });
        } catch (error) {
            console.error('Error al guardar la fila:', error);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (editando === index) {
                handleGuardarEdicion(index); // Al presionar Enter, se guarda la edición
            } else {
                guardarFila();
            }
        }
    };

    const handleEditar = (index) => {
        setEditando(index);
    };

    const handleEliminar = async (index) => {
        const pedidoAEliminar = pedidos[index];
        try {
            await eliminarPedido(pedidoAEliminar); // Eliminar del servidor
            await cargarDatos(); // Recargar los datos para reflejar la eliminación
        } catch (error) {
            console.error('Error al eliminar el pedido:', error);
        }
    };

    const handleGuardarEdicion = async (index) => {
        if (!validarCampos(pedidos[index])) {
            setErrores('Por favor, completa todos los campos obligatorios.');
            return;
        }
        setErrores(''); // Limpiar errores si la validación es correcta
        setEditando(null);

        try {
            await actualizarPedido(pedidos[index]);
            await cargarDatos(); // Recargar los datos para reflejar los cambios
        } catch (error) {
            console.error('Error al actualizar el pedido:', error);
        }
    };

    const handleCambiarEstado = (index) => {
        setEditandoEstado(index); // Activar el modo edición de estado
    };

    const handleVerMapa = (index) => {
        console.log('Viendo el mapa del pedido:', index);
    };

    const handleEnviarTelegram = (index) => {
        console.log('Enviando el pedido por Telegram:', index);
    };

    useEffect(() => {
        cargarDatos();
    }, []);

    return (
        <div>
            <h1>Pedidos Activos</h1>
            {errores && <p style={{ color: 'red' }}>{errores}</p>}
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>N° del Pedido</th>
                        <th>Semáforo</th>
                        <th>Teléfono</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Dirección</th>
                        <th>Producto1</th>
                        <th>Producto2</th>
                        <th>Movil</th>
                        <th>Origen</th>
                        <th>Ciudad</th>
                        <th>Tiempo</th>
                    </tr>
                </thead>
                <tbody>
                    {pedidos.map((pedido, index) => {
                        const { diferenciaMinutos, diferenciaSegundos } = calcularDiferenciaTiempo(pedido.campo_5);
                        const { imagen: imagenSemaforo } = obtenerSemaforoYColor(diferenciaMinutos);

                        return (
                            <tr key={index}>
                                <td>{pedido.campo_1 || index + 1}</td>
                                <td>{pedido.campo_2}</td>
                                <td>
                                    <img src={imagenSemaforo} alt="Semáforo" width="50" />
                                </td>
                                <td>
                                    {editando === index ? (
                                        <input
                                            type="text"
                                            name="campo_4"
                                            value={pedido.campo_4}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                    ) : (
                                        pedido.campo_4
                                    )}
                                </td>
                                <td>{pedido.campo_5}</td>
                                <td>
                                    {editandoEstado === index ? (
                                        <select
                                            value={pedido.campo_6}
                                            onChange={(e) => handleEstadoChange(e, index)}
                                        >
                                            {opcionesEstado.map((estado, idx) => (
                                                <option key={idx} value={estado}>
                                                    {estado}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        pedido.campo_6
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <input
                                            ref={inputDireccionRef}
                                            type="text"
                                            name="campo_7"
                                            value={pedido.campo_7}
                                            onChange={(e) => handleEditChange(e, index)}
                                        />
                                    ) : (
                                        pedido.campo_7
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_8"
                                            value={pedido.campo_8}
                                            onChange={(e) => handleEditChange(e, index)}
                                        >
                                            <option value="">Seleccionar</option>
                                            {opcionesProductos.map((producto, idx) => (
                                                <option key={idx} value={producto}>
                                                    {producto}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        pedido.campo_8
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_9"
                                            value={pedido.campo_9}
                                            onChange={(e) => handleEditChange(e, index)}
                                        >
                                            <option value="">Seleccionar</option>
                                            {opcionesProductos.map((producto, idx) => (
                                                <option key={idx} value={producto}>
                                                    {producto}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        pedido.campo_9
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <input
                                            type="text"
                                            name="campo_11"
                                            value={pedido.campo_11}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                    ) : (
                                        pedido.campo_11
                                    )}
                                </td>
                                <td>{pedido.campo_12}</td>
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_yy"
                                            value={pedido.campo_yy}
                                            onChange={(e) => handleEditChange(e, index)}
                                        >
                                            <option value="">Seleccionar Ciudad</option>
                                            {opcionesCiudad.map((ciudad, idx) => (
                                                <option key={idx} value={ciudad}>
                                                    {ciudad}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        pedido.campo_yy
                                    )}
                                </td>
                                <td>{pedido.tiempoFormateado}</td>
                                {pedido.campo_12 === 'Call Center' && (
                                    <td style={{ display: 'flex', gap: '5px' }}>
                                        {editando === index ? (
                                            <img
                                                src={editarImg}
                                                alt="Guardar"
                                                width="30"
                                                onClick={() => handleGuardarEdicion(index)}
                                            />
                                        ) : (
                                            <img
                                                src={editarImg}
                                                alt="Editar"
                                                width="30"
                                                onClick={() => handleEditar(index)}
                                            />
                                        )}
                                        <img
                                            src={eliminarImg}
                                            alt="Eliminar"
                                            width="30"
                                            onClick={() => handleEliminar(index)}
                                        />
                                        <img
                                            src={cambiarEstadoImg}
                                            alt="Cambiar Estado"
                                            width="30"
                                            onClick={() => handleCambiarEstado(index)}
                                        />
                                        <img
                                            src={mapaImg}
                                            alt="Mapa"
                                            width="30"
                                            onClick={() => handleVerMapa(index)}
                                        />
                                        <img
                                            src={telegramImg}
                                            alt="Telegram"
                                            width="30"
                                            onClick={() => handleEnviarTelegram(index)}
                                        />
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                    <tr>
                        <td>{pedidos.length + 1}</td>
                        <td>{nuevaFila.campo_2}</td>
                        <td></td>
                        <td>
                            <input
                                type="text"
                                name="campo_4"
                                value={nuevaFila.campo_4}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={handleKeyDown}
                            />
                        </td>
                        <td></td>
                        <td>Despacho</td>
                        <td>
                            <input
                                ref={inputDireccionRef}
                                type="text"
                                name="campo_7"
                                value={nuevaFila.campo_7}
                                onChange={handleNuevaFilaChange}
                            />
                        </td>
                        <td>
                            <select
                                name="campo_8"
                                value={nuevaFila.campo_8}
                                onChange={handleNuevaFilaChange}
                            >
                                <option value="">Seleccionar</option>
                                {opcionesProductos.map((producto, index) => (
                                    <option key={index} value={producto}>
                                        {producto}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <select
                                name="campo_9"
                                value={nuevaFila.campo_9}
                                onChange={handleNuevaFilaChange}
                            >
                                <option value="">Seleccionar</option>
                                {opcionesProductos.map((producto, index) => (
                                    <option key={index} value={producto}>
                                        {producto}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <input
                                type="text"
                                name="campo_11"
                                value={nuevaFila.campo_11}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={handleKeyDown}
                            />
                        </td>
                        <td>Call Center</td>
                        <td>
                            <select
                                name="campo_yy"
                                value={nuevaFila.campo_yy}
                                onChange={handleNuevaFilaChange}
                            >
                                <option value="">Seleccionar Ciudad</option>
                                {opcionesCiudad.map((ciudad, index) => (
                                    <option key={index} value={ciudad}>
                                        {ciudad}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Pedidos;
