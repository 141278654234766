import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../styles/Layout.css';

function Layout() {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    return (
        <div className={`layout ${isSidebarCollapsed ? 'collapsed' : ''}`}>
            <Sidebar />
            <div className="main-content">
                <button className="toggle-btn" onClick={toggleSidebar}>
                    {isSidebarCollapsed ? 'Expandir' : 'Contraer'}
                </button>
                <Outlet /> {/* Aquí es donde se renderiza el contenido */}
            </div>
        </div>
    );
}

export default Layout;
